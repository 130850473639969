import { useMemo } from 'react';

import { useAppSelector } from '@/core/redux/hooks';
import { getIsWorkspaceEnv } from '@/utils/environments';

import { getWorkspaceDomainFromUrl } from '../helpers/utils';
import { getDefaultWorkspace, getWorkspaceByDomain } from '../models/workspaces';

export const useActiveWorkspaceId = () => {
    const workspaceDomain = getWorkspaceDomainFromUrl();
    const workspaceFromDomain = useAppSelector(getWorkspaceByDomain(workspaceDomain));
    const defaultWorkspace = useAppSelector(getDefaultWorkspace);

    return useMemo(() => {
        if (getIsWorkspaceEnv()) {
            return workspaceFromDomain?.id;
        }

        return defaultWorkspace?.id;
    }, [defaultWorkspace, workspaceFromDomain?.id]);
};
