import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '@/core/redux/hooks';
import { getIsWorkspaceEnv } from '@/utils/environments';
import { useIsMounted } from '@/utils/hooks/useMounted';

import { getWorkspaceDomainFromUrl, redirectToFirstNonDefaultWorkspace } from '../helpers/utils';
import { getIsWorkspaceInitialized, initWorkspace } from '../models/accountSetup';
import { getWorkspaces } from '../models/workspaces';

export const useWorkspaceInitialize = (workspaceId?: string) => {
    const dispatch = useAppDispatch();
    const workspaces = useAppSelector(getWorkspaces);
    const isWorkspaceInitialized = useAppSelector(getIsWorkspaceInitialized);

    const isWorkspaceEnv = getIsWorkspaceEnv();
    const isWorkspaceBaseEnv = getIsWorkspaceEnv({ genericHost: true });
    const isMounted = useIsMounted();

    useEffect(() => {
        if (isWorkspaceEnv && !isWorkspaceInitialized) {
            const domain = getWorkspaceDomainFromUrl();

            if (domain) {
                dispatch(initWorkspace(getWorkspaceDomainFromUrl()));
            }
        }
    }, [isWorkspaceEnv, isWorkspaceInitialized, dispatch]);

    // Handle Redirects if needed
    useEffect(() => {
        const isOnWorkspaceBaseDomain = !isWorkspaceEnv && isWorkspaceBaseEnv;
        const isWorkspaceMissing = isWorkspaceEnv && isWorkspaceInitialized && !workspaceId;

        if (isOnWorkspaceBaseDomain || isWorkspaceMissing) {
            redirectToFirstNonDefaultWorkspace(workspaces);

            return;
        }
    }, [isWorkspaceBaseEnv, isWorkspaceEnv, isWorkspaceInitialized, workspaceId, workspaces]);

    if (isWorkspaceEnv) {
        return isMounted && isWorkspaceInitialized && !!workspaceId;
    }

    return isMounted && !!workspaceId;
};
