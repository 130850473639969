import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { getInitializedLanguages, setSectionsDataAndPreview } from '../models/sections';

import type { BlockResource } from '../../blocks/types';
import type { SectionResource } from '../types';
import type { Language } from 'types/generic';

interface Params {
    sectionsData: SectionResource[];
    sectionPreviews: { [blockId: string]: BlockResource };
}

export const useSetSectionsDataAndPreview = ({ sectionsData, sectionPreviews }: Params) => {
    const sectionsInitialized = useAppSelector(getInitializedLanguages);
    const { locale } = useRouter();
    const dispatch = useAppDispatch();

    // Set section data for the Editor provided via `getStaticProps` in Redux store if not already set
    useEffect(() => {
        if (!sectionsInitialized.includes(locale as Language)) {
            dispatch(setSectionsDataAndPreview(sectionsData, sectionPreviews, locale as Language));
        }
    }, [dispatch, sectionsData, sectionPreviews, sectionsInitialized, locale]);
};
